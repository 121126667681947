.nav-for-slider .swiper-slide {
  height: auto;
  width: auto;
  cursor: pointer;
 
}
.swiper-wrapper{
  height: auto;
}
.nav-for-slider .swiper-slide img{
  border:2px solid transparent;
  border-radius: 10px;
 
}
.nav-for-slider .swiper-slide-thumb-active img{
  border-color: rgb(79 70 229 );
}